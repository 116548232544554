import styled from 'styled-components'

import clockIcon from '../../../images/clock.svg'

export const BlogIntroWrapper = styled.div`
  padding: 5rem 0;
  background: linear-gradient(0deg, rgba(247, 247, 247, 1) 15%, rgba(255, 255, 255, 1) 15%);

  @media screen and (max-width: 425px) {
    background: linear-gradient(0deg, rgba(247, 247, 247, 1) 10%, rgba(255, 255, 255, 1) 10%);
  }
`
export const BlogIntroHero = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 8rem;
  z-index: 0;

  @media only screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
`

export const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 480px;
  margin-right: 2rem;

  @media only screen and (max-width: 1100px) {
    order: 2;
    width: 100%;
    text-align: center;
    align-items: center;
    margin-right: 0;
    margin-top: 2rem;
  }

  @media screen and (max-width: 425px) {
    align-items: flex-start;

    a {
      order: 2;
    }
  }
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 4rem;
  font-weight: 800;
  line-height: 5.14rem;
  margin-bottom: 2rem;

  @media only screen and (max-width: 1100px) {
    font-size: 1.71rem;
    line-height: 2.3rem;
    margin-bottom: 3rem;
  }

  @media screen and (max-width: 425px) {
    text-align: left;
    margin-bottom: 7rem;
  }
`

export const AuthorBox = styled.div`
  display: flex;
  align-items: center;

  figure {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1rem;
  }

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media only screen and (max-width: 1100px) {
    margin-bottom: 4rem;
  }

  @media screen and (max-width: 425px) {
    order: 1;
    margin-bottom: 1rem;

    figure {
      width: 24px;
      height: 24px;
    }

    a {
      display: none;
    }
  }
`
export const Name = styled.span`
  color: ${({ theme }) => theme.colors.darkGrey};

  &::after {
    content: '-';
    display: inline-flex;
    margin: 0 0.5rem;
  }
`
export const Category = styled.span`
  color: ${({ theme }) => theme.colors.vividBlue};

  @media only screen and (max-width: 339px) {
    margin-top: 1rem;
  }
`

export const TimeToRead = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkGrey};

  span {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.darkGrey};

    &::before {
      content: '';
      display: inline-flex;
      width: 16px;
      height: 16px;
      background-image: url(${clockIcon});
      background-repeat: no-repeat;
      margin: 0 0.5rem 0 1rem;
    }
  }

  @media only screen and (max-width: 339px) {
    margin-top: 1rem;
  }

  @media screen and (max-width: 425px) {
    span:before {
      margin-left: 0;
    }
  }
`
export const TransparentWindow = styled.div`
  position: absolute;
  width: 580px;
  height: 480px;
  border: 4px solid ${({ theme }) => theme.colors.vividBlue};
  right: 0;
  bottom: 0;
  transform: translate(-33px, 40px);
  z-index: -1;
  animation: fadeIn 0.7 2s;

  @media only screen and (max-width: 1100px) {
    display: none;
  }
`
export const ImageContainer = styled.div`
  width: 100%;
  max-width: 580px;
  height: 580px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  animation: fadeIn 0.6s;
  z-index: 1;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 480px;
    width: 400px;
    height: 200px;
    background-position: center;
    background: radial-gradient(
      46.32% 46.32% at 49.17% 106.62%,
      rgba(29, 158, 217, 0.47) 0%,
      rgba(29, 158, 217, 0) 100%
    );
    transform: rotate(180deg) translateY(-100%);
    background-repeat: no-repeat;
    background-size: 480px 272px;
    pointer-events: none;
    z-index: -1;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media screen and (max-width: 1100px) {
    order: 1;
  }
  @media only screen and (max-width: 768px) {
    height: auto;

    &::before {
      left: 50%;
      width: 100%;
      background-size: 100%;
      transform: rotate(180deg) translate(50%, -80%);
    }
  }
`
