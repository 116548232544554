import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import {
  BlogCustomerSuccessSection,
  BlogCustomerSuccessWrapper,
  Title,
  ReadMoreWrapper,
  StyledLink,
  LatestArticles,
  Article,
  ArticleImage,
  ArticleTitle,
  TimeToRead
} from './styles'

import Container from '../../UI/Container'
import ArrowRightGreen from '../../UI/Icons/arrow-right-green'
import { timeToRead } from '../../../helper'

const BlogCustomerSuccess = ({ posts, location, locale, heading, imgPlaceholder }) => {
  if (posts) posts = posts.slice(0, 3)
  const {
    category: { edges: category }
  } = useStaticQuery(graphql`
    query {
      category: allWordpressCategory(filter: { slug: { in: ["customer-success", "price-korisnika"] } }) {
        edges {
          node {
            id
            slug
            lang
            name
          }
        }
      }
    }
  `)
  const getSlugAndName = category
    .filter(({ node: { lang } }) => lang === locale)
    .map(({ node: { slug, name } }) => [slug, name])

  let path = locale === 'en' ? `/${locale}/category/` : `/kategorija/`
  const { text, link_text } = heading

  return (
    <BlogCustomerSuccessSection>
      <Container>
        <BlogCustomerSuccessWrapper>
          <Title>{getSlugAndName[0][1]}</Title>
          <ReadMoreWrapper>
            <p>{text}</p>
            <div>
              <ArrowRightGreen className="right-arrow" />
              <StyledLink to={`${path}${getSlugAndName[0][0]}${location.search}`}>{link_text}</StyledLink>
            </div>
          </ReadMoreWrapper>
          <LatestArticles setSpaceAround={posts.length <= 2}>
            {posts.map(({ node }) => {
              const {
                id,
                title,
                content,
                slug,
                acf: { author_name },
                featured_media
              } = node

              const featuredImage = featured_media !== null && featured_media.localFile.childImageSharp.fluid

              return (
                <Article key={id}>
                  <Link to={`${location.pathname}/${slug}${location.search}`}>
                    <ArticleImage>
                      <Img fluid={featuredImage || imgPlaceholder} alt="" />
                    </ArticleImage>
                    <div>
                      <span>{author_name ? author_name : 'No Author'}</span>
                      <TimeToRead>
                        <span>{`${timeToRead(content)} read`}</span>
                      </TimeToRead>
                    </div>

                    <ArticleTitle className="article-title">{title}</ArticleTitle>
                  </Link>
                </Article>
              )
            })}
          </LatestArticles>
        </BlogCustomerSuccessWrapper>
      </Container>
    </BlogCustomerSuccessSection>
  )
}

BlogCustomerSuccess.defaultProps = {
  location: {
    search: ''
  }
}

export default BlogCustomerSuccess
