import styled from 'styled-components'

import BlogCTA from '../../../images/blog-cta-image.jpg'

export const BlogCTASection = styled.section`
  padding: 5rem 0;
  background-image: linear-gradient(90deg, #0d3151 4.26%, rgba(13, 49, 81, 0) 96.88%),
    linear-gradient(0deg, rgba(13, 49, 81, 0.85), rgba(13, 49, 81, 0.85)), url(${BlogCTA});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`
export const BlogCTAWrapper = styled.div`
  display: flex;
  margin: 5rem 0;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    text-align: center;
    margin: 0;
  }
`
export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  justify-content: center;
  flex: 1;

  p {
    color: ${({ theme }) => theme.colors.pureWhite};
    font-family: 'Barlow';
    font-size: 2.14rem;
    font-weight: 800;
    line-height: 2.85rem;
    width: 80%;
    margin-bottom: 3rem;
  }

  h4 {
    color: ${({ theme }) => theme.colors.veryLightGray};
    font-family: 'Barlow';
    font-size: 1.14rem;
    font-weight: bold;
    line-height: 1.71rem;

    span {
      color: ${({ theme }) => theme.colors.veryLightGray};
      font-family: 'Roboto';
      font-weight: normal;
      font-size: 0.85rem;
      line-height: 1.71rem;
    }
  }

  @media only screen and (max-width: 1024px) {
    align-items: center;

    p {
      width: 100%;
    }
  }
`

export const CTAContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h5 {
    font-family: 'Barlow';
    color: ${({ theme }) => theme.colors.lightestGrey};
    font-size: 3.28rem;
    font-weight: 800;
    line-height: 3.57rem;
    text-transform: uppercase;
    margin-bottom: 2.5rem;
  }

  p {
    color: ${({ theme }) => theme.colors.lightestGrey};
    font-size: 1.14rem;
    line-height: 2rem;
    max-width: 500px;
    margin-bottom: 4rem;
  }

  @media only screen and (max-width: 1024px) {
    align-items: center;

    p {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    h4 {
      font-size: 2.5rem;
      line-height: 2.8rem;
      margin-top: 3rem;
    }
  }
`
