import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Rentlio from '../../../assets/images/Rentlio01.png'

import { LocaleConsumer } from '../../Layout'

import {
  BlogLatestArticlesSection,
  BlogLatestArticlesWrapper,
  LatestArticles,
  Title,
  Article,
  ArticleImage,
  ArticleTitle,
  TimeToRead
} from './styles'

import Container from '../../UI/Container'

import { timeToRead } from '../../../helper'

const BlogLatestArticles = ({ posts, location, imgPlaceholder }) => {
  if (posts) posts = posts.slice(0, 3)
  return (
    <LocaleConsumer>
      {({ i18n }) => (
        <BlogLatestArticlesSection>
          <Container>
            <BlogLatestArticlesWrapper>
              <Title>{i18n.latestArticles}</Title>
              <LatestArticles>
                {posts.map(({ node }) => {
                  const {
                    id,
                    title,
                    content,
                    slug,
                    acf: { author_name },
                    featured_media
                  } = node

                  const featuredImage = featured_media !== null && featured_media.localFile.childImageSharp.fluid

                  return (
                    <Article key={id}>
                      <Link to={`${location.pathname}/${slug}${location.search}`}>
                        <ArticleImage>
                          {/* <Img fluid={featuredImage || imgPlaceholder} alt="" /> */}
                          {featuredImage ? (
                            <Img fluid={featuredImage} />
                          ) : (
                            <img className="rentlioImg" style={{}} src={Rentlio} alt="Rentlio" />
                          )}
                        </ArticleImage>
                        <div>
                          <span>{author_name ? author_name : 'No Author'}</span>
                          <TimeToRead>
                            <span>{`${timeToRead(content)} read`}</span>
                          </TimeToRead>
                        </div>
                        <ArticleTitle className="article-title" dangerouslySetInnerHTML={{ __html: title }} />
                      </Link>
                    </Article>
                  )
                })}
              </LatestArticles>
            </BlogLatestArticlesWrapper>
          </Container>
        </BlogLatestArticlesSection>
      )}
    </LocaleConsumer>
  )
}

BlogLatestArticles.defaultProps = {
  location: {
    search: ''
  }
}

export default BlogLatestArticles
