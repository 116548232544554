import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Header from 'components/Header'
import Footer from 'components/Footer'
import BlogIntro from 'components/Blog-screen/Blog-intro'
import BlogLatestArticles from 'components/Blog-screen/Blog-latest-articles'
import BlogTourismMarketing from 'components/Blog-screen/Blog-tourism-marketing'
import BlogNewsletter from 'components/Blog-screen/Blog-newsletter'
import BlogCustomerSuccess from 'components/Blog-screen/Blog-customer-success'
import BlogMostRead from 'components/Blog-screen/Blog-most-read'
import BlogProductUpdates from 'components/Blog-screen/Blog-product-updates'
import BlogFeaturedBook from 'components/Blog-screen/Blog-featured-book'
// import BlogLifeAtRentlio from 'components/Blog-screen/Blog-life-at-rentlio'
// import BlogCovid19 from 'components/Blog-screen/Blog-covid-19'
import BlogCTA from 'components/Blog-screen/Blog-CTA'

const MainContent = styled.main`
  background-color: #ffffff;
`

const Blog = ({ data, pageContext, location }) => {
  const {
    file: {
      childImageSharp: { fluid: placeholder }
    },
    featuredIntroPost: { edges: featuredIntroPost },
    posts: { edges: posts },
    tourismMarketing: { edges: tourismMarketingPosts },
    customerSuccess: { edges: customerSuccessPosts },
    productUpdates: { edges: productUpdatesPosts },
    // lifeAtRentlio: { edges: lifeAtRentlioPosts },
    covid19: { edges: covid19Posts },
    mostRead: { edges: mostRead },
    startHere: { edges: startHere },

    blog: {
      featured_media,
      title,
      excerpt,
      translations,
      lang,
      yoast_meta: { yoast_wpseo_title, yoast_wpseo_metadesc },
      acf: { featured_book, tourism_marketing, customer_stories, product_updates, covid_19, user_message, cta }
    }
  } = data

  const featuredImage = featured_media !== null ? featured_media?.localFile?.childImageSharp : null
  const seo = {
    title: yoast_wpseo_title || title,
    description: yoast_wpseo_metadesc || excerpt,
    image: featuredImage ? featuredImage.resize : null,
    lang: pageContext.locale,
    pathname: location.pathname
  }

  // Check if there is a tag Blog Featured on any of the posts. If so, show first otherwise show normal first post
  const featuredTitle = featuredIntroPost.length > 0 ? featuredIntroPost[0].node.id : posts[0].node.id
  //filter function that removes featured post from the array of all posts so it doesnt repeat on Newst posts section
  function arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele.node.id != value
    })
  }
  const postsWithoutFeaturedPost = arrayRemove(posts, featuredTitle)

  return (
    <Layout locale={pageContext.locale}>
      <SEO
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
        lang={seo.lang}
        location={location}
        image={seo.image}
      />
      <Header mode="dark" hasBlog locale={pageContext.locale} posts={posts} opacity={0.3} location={location} />

      <BlogIntro
        post={featuredIntroPost.length > 0 ? featuredIntroPost : posts}
        locale={pageContext.locale}
        location={location}
      />
      <MainContent>
        <BlogLatestArticles posts={postsWithoutFeaturedPost} imgPlaceholder={placeholder} location={location} />
        <BlogTourismMarketing
          locale={pageContext.locale}
          latestArticles={postsWithoutFeaturedPost.slice(0, 3)}
          posts={tourismMarketingPosts}
          heading={tourism_marketing}
          imgPlaceholder={placeholder}
          location={location}
          // latestArticles={postsWithoutFeaturedPost.slice(0,3)}
        />
        <BlogNewsletter locale={pageContext.locale} location={location} />
        <BlogCustomerSuccess
          locale={pageContext.locale}
          posts={customerSuccessPosts}
          heading={customer_stories}
          imgPlaceholder={placeholder}
          location={location}
        />
        <BlogMostRead mostRead={mostRead} startHere={startHere} locale={pageContext.locale} location={location} />
        <BlogProductUpdates
          locale={pageContext.locale}
          posts={productUpdatesPosts}
          heading={product_updates}
          imgPlaceholder={placeholder}
          location={location}
        />
        <BlogFeaturedBook data={featured_book} locale={pageContext.locale} location={location} />
        {/* <BlogLifeAtRentlio
          locale={pageContext.locale}
          location={location.pathname}
          posts={lifeAtRentlioPosts}
          heading={life_at_rentlio}
          imgPlaceholder={placeholder}
        /> */}
        {/* <BlogCovid19
          locale={pageContext.locale}
          posts={covid19Posts}
          heading={covid_19}
          imgPlaceholder={placeholder}
          location={location}
        /> */}
        <BlogCTA data={{ user_message, cta }} location={location} />
      </MainContent>
      <Footer locale={pageContext.locale} location={location} />
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query($id: Int, $locale: String!) {
    file(relativePath: { eq: "featured-placeholder.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blog: wordpressPage(wordpress_id: { eq: $id }, lang: { eq: $locale }) {
      title
      excerpt
      translations {
        en
        hr
      }
      lang
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(quality: 85, maxWidth: 1300) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
      acf {
        featured_book {
          show
          title
          subtitle
          description
          button_text
          image {
            localFile {
              childImageSharp {
                fluid(quality: 95, maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        tourism_marketing {
          text
          link_text
        }
        product_updates {
          text
          link_text
        }
        covid_19 {
          text
          link_text
        }
        customer_stories {
          text
          link_text
        }
        user_message {
          text
          user
          role
        }
        cta {
          title
          description
          button_text
          button_link
        }
      }
    }
    featuredIntroPost: allWordpressPost(
      filter: { tags: { elemMatch: { slug: { eq: "blog-featured" } } }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          title
          content
          slug
          excerpt
          date
          acf {
            author_name
            author_image {
              localFile {
                childImageSharp {
                  fluid(quality: 95, maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          categories {
            id
            name
            slug
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 95, maxWidth: 1300) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    posts: allWordpressPost(sort: { fields: date, order: DESC }, filter: { lang: { eq: $locale } }) {
      edges {
        node {
          id
          title
          content
          slug
          excerpt
          date
          acf {
            author_name
            author_image {
              localFile {
                childImageSharp {
                  fluid(quality: 95, maxWidth: 40) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          categories {
            id
            name
            slug
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 95, maxWidth: 450) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    allPages: allWordpressPage {
      edges {
        node {
          translations {
            en
            hr
          }
          slug
        }
      }
    }
    tourismMarketing: allWordpressPost(
      sort: { fields: date, order: DESC }
      filter: {
        categories: { elemMatch: { slug: { in: ["marketing-u-turizmu", "tourism-marketing"] }, lang: { eq: $locale } } }
      }
    ) {
      edges {
        node {
          id
          title
          content
          slug
          acf {
            author_name
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 95, maxWidth: 450) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    customerSuccess: allWordpressPost(
      sort: { fields: date, order: DESC }
      filter: {
        categories: { elemMatch: { slug: { in: ["customer-success", "price-korisnika"] }, lang: { eq: $locale } } }
      }
    ) {
      edges {
        node {
          id
          title
          content
          slug
          acf {
            author_name
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 95, maxWidth: 450) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    productUpdates: allWordpressPost(
      sort: { fields: date, order: DESC }
      filter: {
        categories: { elemMatch: { slug: { in: ["product-updates", "novosti-o-proizvodu"] }, lang: { eq: $locale } } }
      }
    ) {
      edges {
        node {
          id
          title
          content
          slug
          acf {
            author_name
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 95, maxWidth: 450) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    covid19: allWordpressPost(
      sort: { fields: date, order: DESC }
      filter: { categories: { elemMatch: { slug: { in: ["covid-19"] }, lang: { eq: $locale } } } }
    ) {
      edges {
        node {
          id
          title
          content
          slug
          acf {
            author_name
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 95, maxWidth: 450) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    mostRead: allWordpressPost(filter: { tags: { elemMatch: { slug: { eq: "most-read" } } }, lang: { eq: $locale } }) {
      edges {
        node {
          id
          title
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 95, maxWidth: 350, maxHeight: 250) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    startHere: allWordpressPost(
      filter: { tags: { elemMatch: { slug: { eq: "start-here" } } }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          title
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 95, maxWidth: 350, maxHeight: 250) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
