import styled from 'styled-components'

import ArrowRightDark from '../../../images/arrow-right-dark.svg'

export const BlogMostReadSection = styled.section`
  padding: 5rem 0;

  @media screen and (max-width: 425px) {
    padding: 5rem 0 2rem 0;
  }
`

export const BlogMostReadWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 1120px) {
    flex-direction: column;
  }
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  & > div:not(:last-child) {
    margin-bottom: 3rem;
  }

  h4 {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-family: 'Barlow';
    font-size: 1.71rem;
    font-weight: 800;
    line-height: 2.14rem;
    text-transform: uppercase;
    margin: 2rem 0;
    position: relative;
  }
`

export const ArticleBox = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.veryLightGray};
  padding-bottom: 2rem;

  cursor: pointer;

  p {
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 1.14rem;
    line-height: 1.71rem;
    max-width: 380px;
    margin-left: 1rem;
  }

  @media only screen and (max-width: 595px) {
    flex-direction: column;
    align-items: flex-start;

    p {
      font-size: 1.2rem;
      line-height: 1.71rem;
      margin: 1rem 0;
    }

    &:last-child {
      border-bottom: none;
    }
  }
`

export const ImageContainer = styled.figure`
  width: 120px;
  height: 80px;
  overflow: hidden;
  border-radius: 10px;

  @media only screen and (max-width: 595px) {
    width: 300px;
    height: auto;
  }
`
