import styled from 'styled-components'
import { Link } from 'gatsby'

import arrowRightGreen from '../../../images/arrow-right-green.svg'
import clockIcon from '../../../images/clock-light.svg'

export const BlogCustomerSuccessSection = styled.section`
  padding: 5rem 0;
  background: ${({ theme }) => theme.colors.darkBlue};
  background: radial-gradient(28.92% 51.66% at 50% 56.25%, #0d3151 0%, #184873 100%);
`

export const BlogCustomerSuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ReadMoreWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7.14rem;

  p {
    display: flex;
    color: ${({ theme }) => theme.colors.lightestGrey};
    font-size: 1.14rem;
    flex-basis: 50%;
  }

  .right-arrow {
    margin-right: 1.42rem;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 90%;
    margin: 0 auto 2rem auto;

    p {
      margin-bottom: 2rem;
    }
  }
`
export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-family: 'Barlow';
  font-size: 2.14rem;
  font-weight: 800;
  line-height: 2.85rem;
  text-transform: uppercase;
  margin: 0 0 2.85rem 0;

  @media only screen and (max-width: 768px) {
    text-align: center;
    margin: 3rem 0 0.5rem 0;
  }
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-size: 1.14rem;
  line-height: 2rem;
  position: relative;

  &:hover {
    ::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 6px;
      background-color: ${({ theme }) => theme.colors.vividBlue};
      opacity: 0.3;
      bottom: 2px;
      left: 0;
    }
  }
`

export const LatestArticles = styled.div`
  display: flex;
  justify-content: ${({ setSpaceAround }) => (setSpaceAround ? 'space-around' : 'space-between')};
  margin-bottom: 4rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }
`

export const Article = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 340px;
  width: 100%;
  margin: 0 1rem;

  &:first-child {
    margin-right: auto;
    margin-left: 0;
  }

  &:last-child {
    margin-left: auto;
    margin-right: 0;
  }

  a > div {
    display: flex;
  }

  span {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.lightestGrey};
    margin: 1rem 0;
  }

  &:hover {
    .article-title {
      color: ${({ theme }) => theme.colors.vividBlue};
    }
  }

  @media only screen and (max-width: 768px) {
    &:first-child {
      margin-right: 0;
    }

    &:last-child {
      margin-left: 0;
      margin-bottom: 3rem;
    }

    margin-bottom: 7rem;
  }
`
export const ArticleImage = styled.figure`
  max-width: 340px;
  width: 100%;
  height: 230px;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }
`
export const ArticleTitle = styled.h3`
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-family: 'Barlow';
  font-size: 1.42rem;
  font-weight: 600;
  line-height: 1.85rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
`

export const TimeToRead = styled.div`
  display: flex;
  align-items: center;

  span {
    color: ${({ theme }) => theme.colors.lightestGrey};
    opacity: 0.6;

    &::before {
      content: '';
      display: inline-flex;
      width: 16px;
      height: 16px;
      background-image: url(${clockIcon});
      background-repeat: no-repeat;
      margin: 0 0.5rem 0 1rem;
    }
  }
`
