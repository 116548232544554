import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Rentlio from '../../../assets/images/Rentlio01.png'

import {
  BlogIntroWrapper,
  BlogIntroHero,
  TitleBox,
  Title,
  AuthorBox,
  TimeToRead,
  ImageContainer,
  Name,
  Category,
  TransparentWindow
} from './styles'

import Container from '../../UI/Container'

import { timeToRead } from '../../../helper'

const BlogIntro = ({ post, location, locale, imgPlaceholder }) => {
  const {
    title,
    slug,
    content,
    acf: {
      author_name,
      author_image: {
        localFile: {
          childImageSharp: { fluid: authorImage }
        }
      }
    },
    categories
  } = post[0].node
  const featureFotka =
    post[0].node.featured_media !== null && post[0].node.featured_media.localFile.childImageSharp.fluid

  let path = locale === 'en' ? `/${locale}/category/` : `/kategorija/`
  return (
    <BlogIntroWrapper>
      <Container>
        <BlogIntroHero>
          <TitleBox>
            <Link to={`${location.pathname}/${slug}${location.search}`}>
              <Title dangerouslySetInnerHTML={{ __html: title }} />
            </Link>
            <AuthorBox>
              <figure>
                <Img fluid={authorImage} alt="" />
              </figure>
              <Name>{author_name ? author_name : 'No Author'}</Name>
              <Link to={`${path}${categories[0].slug}${location.search}`}>
                <Category>{categories[0].name}</Category>
              </Link>
              <TimeToRead>
                <span>{`${timeToRead(content)} read`}</span>
              </TimeToRead>
            </AuthorBox>
          </TitleBox>
          <ImageContainer>
            {featureFotka ? <Img fluid={featureFotka} /> : <img src={Rentlio} alt="Rentlio" />}
            {/* <Img fluid={featureFotka} /> */}
            {/* <Img fluid={imgPlaceholder} alt="" /> */}
          </ImageContainer>
          <TransparentWindow />
        </BlogIntroHero>
      </Container>
    </BlogIntroWrapper>
  )
}

BlogIntro.defaultProps = {
  location: {
    search: ''
  }
}

export default BlogIntro
