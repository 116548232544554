import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { handleTrackedRentlioWebappClick } from 'helper/url'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

import { BlogFeaturedBookSection, DarkButton, BlogFeaturedBookWrapper, Description, ImageContainer } from './styles'

import Container from '../../UI/Container'

const BlogFeaturedBook = ({ data, locale }) => {
  const {
    show,
    title,
    subtitle,
    description,
    button_text,
    image: {
      localFile: {
        childImageSharp: { fluid }
      }
    }
  } = data

  const { books } = useStaticQuery(graphql`
    {
      books: allFile(filter: { extension: { eq: "pdf" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `)

  const getEbook = (locale) => {
    const book = books.edges.map((file, index) => file.node.publicURL)
    if (locale === 'hr') {
      return book[1]
    }
    return book[0]
  }

  const mixpanel = useMixpanel()

  return (
    <>
      {show && (
        <>
          <BlogFeaturedBookSection>
            <Container>
              <BlogFeaturedBookWrapper>
                <Description>
                  <h4>{subtitle}</h4>
                  <h3>{title}</h3>
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                  <DarkButton href={getEbook(locale)} download onClick={(e) => handleTrackedRentlioWebappClick(e, getEbook(locale), location.search, mixpanel, 'fb_advertising_guide_downloaded', 'blog_book', false, true)}>
                    {button_text}
                  </DarkButton>
                </Description>
                <ImageContainer>
                  <Img fluid={fluid} />
                </ImageContainer>
              </BlogFeaturedBookWrapper>
            </Container>
          </BlogFeaturedBookSection>
        </>
      )}
    </>
  )
}

export default BlogFeaturedBook
