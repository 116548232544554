import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Rentlio from '../../../assets/images/Rentlio01.png'

import {
  BlogTourismMarketingSection,
  BlogTourismMarketingWrapper,
  ReadMoreWrapper,
  StyledLink,
  Title,
  LatestArticles,
  Article,
  ArticleImage,
  ArticleTitle,
  TimeToRead
} from './styles'

import Container from '../../UI/Container'
import ArrowRightGreen from '../../UI/Icons/arrow-right-green'
import { timeToRead } from '../../../helper'

const BlogTourismMarketing = ({ posts, location, locale, heading, imgPlaceholder, latestArticles }) => {
  //if (posts) posts = posts.slice(0, 3)//

  const {
    category: { edges: category }
  } = useStaticQuery(graphql`
    query {
      category: allWordpressCategory(filter: { slug: { in: ["marketing-u-turizmu", "tourism-marketing"] } }) {
        edges {
          node {
            id
            slug
            lang
            name
          }
        }
      }
    }
  `)
  const getSlugAndName = category
    .filter(({ node: { lang } }) => lang === locale)
    .map(({ node: { slug, name } }) => [slug, name])

  let path = locale === 'en' ? `/${locale}/category/` : `/kategorija/`
  const { text, link_text } = heading


//filter posts from category Tourism marketing so they dont repeat in Latest articles
const triPostaMarketingBezNajnovijih = posts.filter(function(val,index) { 
  return latestArticles.map(function(e) { return e.node.id; }).indexOf(val.node.id) == -1;
});


  return (
    <BlogTourismMarketingSection>
      <Container>
        <BlogTourismMarketingWrapper>
          <Title>{getSlugAndName[0][1]}</Title>
          <ReadMoreWrapper>
            <p>{text}</p>
            <div>
              <ArrowRightGreen className="right-arrow" />
              <StyledLink to={`${path}${getSlugAndName[0][0]}${location.search}`}>{link_text}</StyledLink>
            </div>
          </ReadMoreWrapper>
          <LatestArticles setSpaceAround={posts.length <= 2}>
            {triPostaMarketingBezNajnovijih.slice(0,3).map(({ node }) => {
              const {
                id,
                title,
                content,
                slug,
                acf: { author_name },
                featured_media
              } = node

              const featuredImage = featured_media !== null && featured_media.localFile.childImageSharp.fluid

              return (
                <Article key={id}>
                  <Link to={`${location.pathname}/${slug}${location.search}`}>
                    <ArticleImage>
                      {/* <Img fluid={featuredImage || imgPlaceholder} alt="" /> */}
                      {featuredImage ? (
                        <Img fluid={featuredImage} />
                      ) : (
                        <img className="rentlioImg" style={{}} src={Rentlio} alt="Rentlio" />
                      )}
                    </ArticleImage>
                    <div>
                      <span>{author_name ? author_name : 'No Author'}</span>
                      <TimeToRead>
                        <span>{`${timeToRead(content)} read`}</span>
                      </TimeToRead>
                    </div>
                    <ArticleTitle className="article-title" dangerouslySetInnerHTML={{ __html: title }} />
                  </Link>
                </Article>
              )
            })}
          </LatestArticles>
        </BlogTourismMarketingWrapper>
      </Container>
    </BlogTourismMarketingSection>
  )
}

BlogTourismMarketing.defaultProps = {
  location: {
    search: ''
  }
}

export default BlogTourismMarketing
