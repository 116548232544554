import styled from 'styled-components'
import { Link } from 'gatsby'

export const BlogFeaturedBookSection = styled.section`
  padding: 5rem 0;
  background: linear-gradient(301.25deg, #0d3151 -11.21%, rgba(13, 49, 81, 0) 74.79%),
    radial-gradient(50% 50% at 50% 50%, #1d9ed9 0%, #1a8ec2 100%);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background: white;
    width: 100%;
    height: 100%;
    top: -1px;

    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
      -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 100% 18%);
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 100% 18%);
    }
  }

  @media only screen and (max-width: 1190px) {
    &::before {
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 100% 4%);
    }
  }

  @media screen and (max-width: 425px) {
    padding-top: 7rem;
  }
`

export const BlogFeaturedBookWrapper = styled.div`
  display: flex;
  position: relative;

  @media only screen and (max-width: 1190px) {
    flex-direction: column;
    align-items: center;
  }
`
export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-basis: 50%;

  h3 {
    color: ${({ theme }) => theme.colors.lightestGrey};
    font-family: 'Barlow';
    font-size: 2.85rem;
    font-weight: bold;
    line-height: 3.28rem;
    margin: 2rem 0;
  }

  h4 {
    color: ${({ theme }) => theme.colors.lightestGrey};
    font-family: 'Barlow';
    font-size: 1.42rem;
    font-weight: 500;
    line-height: 2.14rem;
    text-transform: uppercase;
  }

  p {
    color: ${({ theme }) => theme.colors.lightestGrey};
    font-size: 1.14rem;
    line-height: 1.71rem;
    margin-bottom: 4rem;
  }

  @media only screen and (max-width: 1190px) {
    width: 90%;
    align-items: center;
  }

  @media screen and (max-width: 425px) {
    h3 {
      font-size: 2.5rem;
      line-height: 3rem;
    }
  }
`

export const ImageContainer = styled.figure`
  width: 550px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(5%, -11%);

  @media only screen and (max-width: 1190px) {
    position: relative;
    height: auto;
    transform: translate(10%, 10%);
    width: 80%;
  }
`
export const DarkButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  width: auto;
  min-height: 36px;
  font-size: 1.28rem;
  font-weight: bold;
  padding: 1.2rem 3rem;
  border-radius: 8px;
  text-transform: uppercase;
  white-space: nowrap;
  background: ${({ theme }) => theme.colors.veryDarkBlue};
  color: ${({ theme }) => theme.colors.pureWhite};
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: 0px 4px 10px rgba(72, 72, 72, 0.2);
  cursor: pointer;
  appearance: none;
  border: none;

  &:hover {
    box-shadow: 0px 4px 10px rgba(72, 72, 72, 0.7);
    background-position: 98% center;
  }

  &:focus {
    outline: none;
  }
`
