import styled, { css } from 'styled-components'

export const BlogNewsletterSection = styled.section`
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #f7f7f7 100%);
`

export const BlogNewsletterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 768px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    height: 100%;
  }
`

export const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  align-items: flex-start;
  padding: 11.5rem 0rem;
  position: relative;

  @media screen and (max-width: 1200px) {
    align-items: center;
    padding: 5rem 2rem;
  }

  @media screen and (max-width: 425px) {
    padding: 0;
  }
`

export const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 50%;
  align-items: center;
`

export const Row = styled.div`
  display: flex;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const Title = styled.h3`
  font-family: 'Barlow';
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-size: 2.14rem;
  font-weight: 800;
  line-height: 2.85rem;
  margin-bottom: 2.5rem;

  @media screen and (max-width: 1200px) {
    text-align: center;
  }

  @media screen and (max-width: 425px) {
    padding: 1rem 2rem;
    font-size: 1.71rem;
    line-height: 2rem;
    margin-top: 5rem;
  }
`

export const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  line-height: 2rem;
  width: 500px;
  margin-bottom: 2.85rem;

  @media screen and (max-width: 1200px) {
    width: 90%;
    margin: 0 auto 2.85rem auto;
    text-align: center;
  }
`
export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: url(${({ imageUrl }) => imageUrl}) no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 1200px) {
    height: 500px;
    text-align: center;
  }
`

export const NewsletterImage = styled.figure`
  position: absolute;
  width: 200px;
  height: auto;
  right: 0;
  transform: translate(25%, -20%);

  @media screen and (max-width: 1200px) {
    position: relative;
    order: 1;
    transform: none;
  }
`

export const Explore = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 55%;
  background-color: ${({ theme }) => theme.colors.veryDarkBlue};
  border-radius: 10px;
  padding: 3rem;
  transform: translateY(100px);

  @media only screen and (max-width: 1200px) {
    transform: translateY(0px);
  }

  @media screen and (max-width: 425px) {
    width: 75%;
  }
`

export const ImageContainerText = styled.span`
  color: ${({ theme }) => theme.colors.lightestGrey};
  text-align: center;
  font-family: 'Barlow';
  font-size: 2rem;
  font-weight: 800;
  line-height: 2.5rem;
  margin-bottom: 2rem;

  @media only screen and (max-width: 1200px) {
    font-size: 1.75rem;
    line-height: 2.57rem;
  }
`

export const FormWrapper = styled.form`
  margin-bottom: 2rem;

  input {
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 1rem;
    font-weight: 300;
    line-height: 2rem;
    background: #ffffff;
    border: 1px solid #cfcfcf;
    border-radius: 8px;
    width: 300px;
    height: 50px;
    padding: 1rem;
    margin-bottom: 1.42rem;

    &::placeholder {
      color: ${({ theme }) => theme.colors.darkGrey};
    }
    &:focus {
      outline: none;
    }
  }

  @media screen and (max-width: 1200px) {
    order: 3;
  }
`
export const FormButtons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 425px) {
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;
  }
`

export const SubmitButton = styled.button`
  display: block;
  background: ${({ theme }) => theme.colors.vividBlue};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.pureWhite};
  font-size: 1.28rem;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 19px 3rem;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  border: none;
  margin-right: 2rem;
  transition: all 0.2s ease-in-out;
  min-width: 206px;

  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.5);
    background-color: hsla(199, 76%, 43%, 1);
    transform: translateY(-3px);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.3);
  }

  @media only screen and (max-width: 768px) {
    padding: 19px 2rem;
  }

  @media screen and (max-width: 425px) {
    margin: 0;
  }
`
export const PrivacyBox = styled.div`
  display: block;

  span {
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 0.85rem;
    font-weight: 500;
    line-height: 1;
  }

  a {
    color: ${({ theme }) => theme.colors.vividBlue};

    &:hover {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 1200px) {
    margin-top: 2rem;
  }
  @media screen and (max-width: 425px) {
    margin: 0 0 4rem 0;
  }
`
export const ConformationMsg = styled.div`
  display: flex;
  height: auto;

  animation: fadeIn 0.2s ease-in-out;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export const SendingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.47rem auto;

  span {
    display: inline-flex;
    width: 8px;
    height: 8px;
    margin: 0 0.3rem;
    background-color: ${({ theme }) => theme.colors.pureWhite};
    border-radius: 50%;
    animation: bounce 0.4s linear infinite;

    &:nth-child(2) {
      animation-delay: 0.5s;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }

  @keyframes bounce {
    20% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-10px);
    }
  }
`

export const ErrorMsg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -37px;
  color: ${({ theme }) => theme.colors.darkGrey};
  padding: 1rem 0;
  border-radius: 4px;
  height: 30px;
  z-index: 999;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.4s ease-in-out;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};

  @media screen and (max-width: 1200px) {
    order: 2;
  }
`
