import styled from 'styled-components'

import clockIcon from '../../../images/clock.svg'

export const BlogLatestArticlesSection = styled.section`
  padding: 5rem 0;
  background-color: ${({ theme }) => theme.colors.lightestGrey};

  @media screen and (max-width: 425px) {
    padding: 1rem 0;
  }
`

export const BlogLatestArticlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const LatestArticles = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }
`
export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 2.14rem;
  font-weight: 800;
  line-height: 2.85rem;
  text-transform: uppercase;
  margin-bottom: 5.71rem;

  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`

export const Article = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 340px;
  width: 100%;
  margin: 0 1rem;

  &:first-child {
    margin-right: auto;
    margin-left: 0;
  }

  &:last-child {
    margin-left: auto;
    margin-right: 0;
  }

  a > div {
    display: flex;
  }

  span {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    margin: 1rem 0;
  }

  &:hover {
    .article-title {
      color: ${({ theme }) => theme.colors.vividBlue};
    }
  }

  @media only screen and (max-width: 768px) {
    &:first-child {
      margin-right: 0;
    }

    &:last-child {
      margin-left: 0;
      margin-bottom: 3rem;
    }

    margin-bottom: 7rem;
  }
`
export const ArticleImage = styled.figure`
  max-width: 340px;
  width: 100%;
  height: 230px;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }
  .rentlioImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }
`
export const ArticleTitle = styled.h3`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 1.42rem;
  font-weight: 600;
  line-height: 1.85rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
`

export const TimeToRead = styled.div`
  display: flex;
  align-items: center;

  span {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    opacity: 0.6;

    &::before {
      content: '';
      display: inline-flex;
      width: 16px;
      height: 16px;
      background-image: url(${clockIcon});
      background-repeat: no-repeat;
      margin: 0 0.5rem 0 1rem;
    }
  }
`
