import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { handleTrackedRentlioWebappClick } from 'helper/url'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

import {
  BlogNewsletterSection,
  BlogNewsletterWrapper,
  ColumnLeft,
  ColumnRight,
  Row,
  Title,
  Subtitle,
  ImageContainer,
  FormWrapper,
  FormButtons,
  ConformationMsg,
  ErrorMsg,
  SendingWrapper,
  PrivacyBox,
  SubmitButton,
  NewsletterImage,
  ImageContainerText,
  Explore
} from './styles'

import Container from '../../UI/Container'
import { LocaleConsumer } from '../../Layout'
import Select from './Select'
import Button from '../../Button'

const BlogNewsletter = ({ locale, location }) => {
  const {
    newsletter: {
      childImageSharp: { fluid }
    },
    ctaImage: {
      childImageSharp: {
        fluid: { src }
      }
    }
  } = useStaticQuery(graphql`
    query {
      newsletter: file(relativePath: { eq: "images/newsletter-blog.png" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ctaImage: file(relativePath: { eq: "images/cta-blog.jpg" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const MAILCHIMPLIST = locale === 'hr' ? process.env.GATSBY_MAILCHIMP_LIST_HR : process.env.GATSBY_MAILCHIMP_LIST_EN
  const mixpanel = useMixpanel()

  const [email, setEmail] = useState('')
  const [selectedIndustry, setSelect] = useState('')
  const [msg, setMsg] = useState(null)
  const [errorMsg, setErrorMsg] = useState(null)
  const [sending, setSending] = useState(false)

  const onSelectedIndustry = (item) => {
    setSelect(item)
  }

  const handleChange = (e) => {
    const { value } = e.target
    setEmail(value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSending(true)
    try {
      const result = await addToMailchimp(
        email,
        {
          INDUSTRY: selectedIndustry
        },
        MAILCHIMPLIST
      )

      if (result.result !== 'success') {
        setErrorMsg(result.msg)
        setSending(true)

        setTimeout(() => {
          setErrorMsg(null)
          setSending(false)
        }, 4000)

        return
      }
      handleTrackedRentlioWebappClick(e, '', '', mixpanel, 'newsletter_signed_up', 'blog_section', false, false)
      setMsg(result.msg)
    } catch (error) {
      console.log(error)
    }
    setSending(false)
  }

  const Sending = () => {
    return (
      <SendingWrapper>
        <span />
        <span />
        <span />
      </SendingWrapper>
    )
  }
  const privacy = locale === 'en' ? `/${locale}/privacy-policy` : '/izjava-o-privatnosti'
  const linkToHome = locale === 'en' ? `/${locale}/` : '/'

  return (
    <LocaleConsumer>
      {({ i18n }) => (
        <BlogNewsletterSection>
          <Container addMargin width={1600}>
            <BlogNewsletterWrapper>
              <Container addMargin width={470}>
                <ColumnLeft>
                  <Title>{i18n.newsLetterTitle}</Title>
                  <Subtitle>{i18n.newsLetterSubtitle}</Subtitle>
                  {msg ? (
                    <ConformationMsg>
                      <h3>{msg}</h3>
                    </ConformationMsg>
                  ) : (
                    <>
                      <ErrorMsg show={errorMsg !== null}>{errorMsg}</ErrorMsg>
                      <Row>
                        <FormWrapper onSubmit={handleSubmit}>
                          <input
                            type="email"
                            name={email}
                            placeholder={i18n.yourEmailPlaceholder}
                            autoComplete="off"
                            onChange={handleChange}
                            required
                          />
                          <Select label={i18n.yourIndustry} i18n={i18n} onSelectedIndustry={onSelectedIndustry} />
                          <FormButtons>
                            <SubmitButton type="submit">
                              {sending ? <Sending /> : i18n.newsLetterSubscribe}
                            </SubmitButton>
                            <PrivacyBox>
                              <span>
                                {i18n.checkPrivacy}
                                <Link to={`${privacy}${location.search}`}> {i18n.privacyPolicy}.</Link>
                              </span>
                            </PrivacyBox>
                          </FormButtons>
                        </FormWrapper>
                        <NewsletterImage>
                          <Img fluid={fluid} />
                        </NewsletterImage>
                      </Row>
                    </>
                  )}
                </ColumnLeft>
              </Container>
              <ColumnRight>
                <ImageContainer imageUrl={src}>
                  <Explore>
                    <ImageContainerText>{i18n.newsLetterCTA}</ImageContainerText>
                    <Button type="large" href={`${linkToHome}${location.search}`}>
                      {i18n.exploreRentlio}
                    </Button>
                  </Explore>
                </ImageContainer>
              </ColumnRight>
            </BlogNewsletterWrapper>
          </Container>
        </BlogNewsletterSection>
      )}
    </LocaleConsumer>
  )
}

BlogNewsletter.defaultProps = {
  location: {
    search: ''
  }
}

export default BlogNewsletter
