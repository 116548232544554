import React from 'react'
import { Link } from 'gatsby'

import { BlogCTASection, BlogCTAWrapper, TextContent, CTAContent } from './styles'

import Container from '../../UI/Container'
import Button from '../../Button'

const BlogCTA = ({ data, location }) => {
  const {
    user_message: { text, user, role },
    cta: { title, description, button_text, button_link }
  } = data

  return (
    <BlogCTASection>
      <Container>
        <BlogCTAWrapper>
          <TextContent>
            <p>{text}</p>
            <h4>
              {user} <span>{role}</span>
            </h4>
          </TextContent>
          <CTAContent>
            <h5>{title}</h5>
            <div dangerouslySetInnerHTML={{ __html: description }} />
            <Button type="large" href={`${button_link}${location.search}`} target="_blank" rel="noreferrer">
              {button_text}
            </Button>
          </CTAContent>
        </BlogCTAWrapper>
      </Container>
    </BlogCTASection>
  )
}

BlogCTA.defaultProps = {
  location: {
    search: ''
  }
}

export default BlogCTA
