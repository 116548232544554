import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import { BlogMostReadSection, BlogMostReadWrapper, ImageContainer, ArticleBox, Column } from './styles'

import Container from '../../UI/Container'
import { LocaleConsumer } from '../../Layout'

const BlogMostRead = ({ mostRead, startHere, locale, location }) => {
  let path = locale === 'en' ? `/${locale}/` : `/`
 
  return (
    <LocaleConsumer>
      {({ i18n }) => (
        <BlogMostReadSection>
          <Container>
            <BlogMostReadWrapper>
              <Column>
                <h4>{i18n.mostRead}</h4>
                {mostRead.map(
                  ({
                    node: {
                      id,
                      title,
                      slug,
                      featured_media: {
                        localFile: {
                          childImageSharp: { fluid }
                        }
                      }
                    }
                  }) => {
                    return (
                      <ArticleBox key={id}>
                        <ImageContainer>
                          <Img fluid={fluid} />
                        </ImageContainer>
                        <Link to={`${path}blog/${slug}${location.search}`}>
                          <p dangerouslySetInnerHTML={{ __html: title }} />
                        </Link>
                      </ArticleBox>
                    )
                  }
                )}
              </Column>
              <Column>
                <h4>{i18n.startHere}</h4>
                {startHere.map(
                  ({
                    node: {
                      id,
                      title,
                      slug,
                      featured_media: {
                        localFile: {
                          childImageSharp: { fluid }
                        }
                      }
                    }
                  }) => {
                    return (
                      <ArticleBox key={id}>
                        <ImageContainer>
                          <Img fluid={fluid} />
                        </ImageContainer>
                        <Link to={`${path}blog/${slug}${location.search}`}>
                          <p dangerouslySetInnerHTML={{ __html: title }} />
                        </Link>
                      </ArticleBox>
                    )
                  }
                )}
              </Column>
            </BlogMostReadWrapper>
          </Container>
        </BlogMostReadSection>
      )}
    </LocaleConsumer>
  )
}

BlogMostRead.defaultProps = {
  location: {
    search: ''
  }
}

export default BlogMostRead
